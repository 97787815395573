import { Carousel, Progress } from 'antd/es'
import styled from 'styled-components'
import { useState } from 'react'
import Union from '@/assets/icons/union.svg'
import { useNavigate } from 'react-router-dom'
import { useBannerNews } from '@/hooks/useNewsService'
import { formatDate, truncateStringByWords } from '@/utils/halper'

export const HomeHero = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const navigate = useNavigate()

  const { data } = useBannerNews()

  if (data?.length === 0) return null

  return (
    <div className="relative px-4 lg:px-0">
      <StyledCarousel
        autoplay
        arrows={true}
        dots={false}
        beforeChange={(_, to) => setCurrentSlide(to)}
        afterChange={(current) => setCurrentSlide(current)}
      >
        {data?.map((item) => (
          <div
            key={item.id}
            className="!grid h-[500px] cursor-pointer grid-cols-1 overflow-hidden rounded-primary lg:grid-cols-2"
            onClick={() => navigate(`/press-service/news/${item.alias}`)}
          >
            <div className="relative w-full bg-gradient-primary px-[50px] py-[50px] font-helvetica text-white lg:px-[76px]">
              <h4 className="mb-[7px] text-xs font-bold uppercase leading-[120%]">{item.category}</h4>
              <h4 className="mb-[51px] text-base font-bold uppercase leading-[120%]">{formatDate(item.createdAt)}</h4>
              <h1 className="text-2xl font-bold md:text-5xl lg:text-3xl 2xl:text-5xl">
                {truncateStringByWords(item.title, 90)}
              </h1>
              <hr className="my-[35px] h-[2px] w-[68px] bg-white" />
              <h3 className="pb-16 text-base font-[550] leading-[130%] md:text-xl lg:text-lg 2xl:text-xl">
                {truncateStringByWords(item.description)}
              </h3>
              <img src={Union} className="absolute right-8 top-0 md:right-16" alt="" />
            </div>
            <div className="hidden w-full lg:block">
              <img src={item.bannerImageUrl} alt={item.title} className="h-full w-full object-cover" />
            </div>
          </div>
        ))}
      </StyledCarousel>
      <div className="absolute left-[76px] top-[450px] flex w-[160px] items-center gap-4 pl-7 text-base font-[550] uppercase leading-[130%] text-white lg:left-[76px]">
        <div>01</div>
        <Progress
          percent={((currentSlide + 1) / 3) * 100}
          strokeColor="#0047AB"
          trailColor="white"
          size={{ width: 80, height: 2 }}
          className="flex items-center"
          showInfo={false}
        />
        <div>03</div>
      </div>
    </div>
  )
}

const StyledCarousel = styled(Carousel)`
  .slick-prev,
  .slick-next {
    top: 460px;
  }

  .slick-prev {
    left: 50px;
  }
  .slick-next {
    left: 270px;
  }
  @media (min-width: 1024px) {
    .slick-prev {
      left: 76px;
    }
  }
`
