import DrugProductDetails from '@/pages/home/drugs/DrugProductDetails'
import DrugsPage from '@/pages/home/drugs/Drugs'
import HomePage from '@/pages/home/home'
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom'
import React, { Suspense, lazy } from 'react'
import paths from './paths'
import { Spin } from 'antd'
import GXPApplications from '@/pages/Dashboard/gxp/GXPApplications'
import DrugItemsScreen from '@/pages/home/drugs/DrugItems'

const withSuspense = (cb: () => Promise<{ default: React.ComponentType<any> }>) => {
  const L = lazy(cb)

  return (props: any) => (
    <Suspense fallback={<Spin fullscreen />}>
      <L {...props} />
    </Suspense>
  )
}

// home
const HomeLayout = withSuspense(() => import('@/layouts/HomeLayout/layout'))
const Dashbord = withSuspense(() => import('@/pages/Dashboard/AdminDashboard'))
const PharmProduct = withSuspense(() => import('@/pages/pharmProduct'))

// agency
const AboutUniversity = withSuspense(() => import('@/pages/home/agency/AboutUniversity'))
const AgencyAbout = withSuspense(() => import('@/pages/home/agency/AgencyAbout'))
const Contacts = withSuspense(() => import('@/pages/home/contacts/Contacts'))
const AgencyManagement = withSuspense(() => import('@/pages/home/agency/AgencyManagement'))
const PharmPark = withSuspense(() => import('@/pages/home/agency/PharmPark'))
const MediaTemplate = withSuspense(() => import('@/pages/home/agency/pharmParkPages/MediaPages/MediaTemplate'))
const AboutGXP = withSuspense(() => import('@/pages/home/agency/AboutGXP'))

// press-service
const News = withSuspense(() => import('@/pages/home/press-service/News'))
const NewsDetails = withSuspense(() => import('@/pages/home/press-service/NewsDetails'))
const AboutService = withSuspense(() => import('@/pages/home/press-service/AboutService'))
const PhotoGallery = withSuspense(() => import('@/pages/home/press-service/PhotoGallery'))
const VideoGallery = withSuspense(() => import('@/pages/home/press-service/VideoGallery'))
const InfoGraphics = withSuspense(() => import('@/pages/home/press-service/InfoGraphics'))
const ReferencePrices = withSuspense(() => import('@/pages/home/referencePrices/ReferencePrices'))
const Manufacturers = withSuspense(() => import('@/pages/home/manufacturers/Manufacturers'))
const ManufacturerDetails = withSuspense(() => import('@/pages/home/manufacturers/ManufacturerDetails'))

// regulator
const RegulatorPanel = withSuspense(() => import('@/layouts/DashbordLayout/RegulatorDashboardLayout'))
const RegulatorHome = withSuspense(() => import('@/pages/Dashboard/regulator/RegulatorHome'))
const RegulatorStatistics = withSuspense(() => import('@/pages/Dashboard/regulator/RegulatorStatistics'))
const RegulatorProfile = withSuspense(() => import('@/pages/Dashboard/regulator/RegulatorProfile'))
const RegulatorSettings = withSuspense(() => import('@/pages/Dashboard/regulator/RegulatorSettings'))
const RegulatorHandbook = withSuspense(() => import('@/pages/Dashboard/regulator/RegulatorHandbook'))
const RegulatorHandbookCreate = withSuspense(() => import('@/pages/Dashboard/regulator/RegulatorHandbookCreate'))

// gxp
const GxpPanel = withSuspense(() => import('@/layouts/DashbordLayout/GxPDashboarLayout'))
const GxpHome = withSuspense(() => import('@/pages/Dashboard/gxp/GXPHome'))
const GXPCompany = withSuspense(() => import('@/pages/Dashboard/gxp/company/companyData'))
const GXPCompanyBranch = withSuspense(() => import('@/pages/Dashboard/gxp/company/branch'))
const GXPCompanyAddBranch = withSuspense(() => import('@/pages/Dashboard/gxp/company/addBranch'))
const CreateApplicationContainer = withSuspense(() => import('@/pages/Dashboard/gxp/CreateApplicationContainer'))

// admin
const AdminPanel = withSuspense(() => import('@/layouts/DashbordLayout/AdminDashboardLayout'))
const NewsScreen = withSuspense(() => import('@/pages/Dashboard/admin/NewsScreen'))
const AddNewsEditor = withSuspense(() => import('@/pages/Dashboard/admin/AddNewsEditor'))
const AdminReferencePrices = withSuspense(() => import('@/pages/Dashboard/admin/ReferencePrices'))
const AddReferencePrice = withSuspense(() => import('@/pages/Dashboard/admin/AddReferencePrice'))

export const routes = createBrowserRouter([
  {
    path: paths.HOME,
    element: <HomeLayout />,
    children: [
      {
        path: paths.HOME,
        element: <HomePage />,
        index: true,
      },
      {
        path: paths.MANUFACTURERS,
        element: <Manufacturers />,
      },
      {
        path: paths.NEWS,
        element: <News />,
      },
      {
        path: paths.NEWS_DETAILS,
        element: <NewsDetails />,
      },
      {
        path: paths.ABOUT_SERVICE,
        element: <AboutService />,
      },
      {
        path: paths.PHOTO_GALLERY,
        element: <PhotoGallery />,
      },
      {
        path: paths.VIDEO_GALLERY,
        element: <VideoGallery />,
      },
      {
        path: paths.INFOGRAPHICS,
        element: <InfoGraphics />,
      },
      {
        path: paths.DRUGS,
        element: <DrugsPage />,
      },
      {
        path: paths.REFERENCE_PRICES,
        element: <ReferencePrices />,
      },
      {
        path: paths.DRUGS_ITEM,
        element: <DrugItemsScreen />,
      },
      {
        path: paths.DRUGS_DETAILS,
        element: <DrugProductDetails />,
      },
      {
        path: paths.PHARM_PRODUCT,
        element: <PharmProduct />,
      },
      {
        path: paths.MANUFACTURERS_DETAILS,
        element: <ManufacturerDetails />,
      },
      {
        path: paths.CONTACTS,
        element: <Contacts />,
      },
      {
        path: paths.agencyPaths.AGENCY_HOME,
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <Navigate to={paths.agencyPaths.AGENCY_ABOUT_AGENCY} replace />,
          },
          {
            path: paths.agencyPaths.AGENCY_UNIVERSITY,
            element: <AboutUniversity />,
          },
          {
            path: paths.agencyPaths.AGENCY_ABOUT_AGENCY,
            element: <AgencyAbout />,
          },
          {
            path: paths.agencyPaths.AGENCY_MANAGEMENT,
            element: <AgencyManagement />,
          },
          {
            path: paths.agencyPaths.AGENCY_TASHKENTPHARM,
            element: <PharmPark />,
            children: [
              {
                path: paths.agencyPaths.AGENCY_TASHKENTPHARM_MEDIA,
                element: <MediaTemplate />,
              },
            ],
          },
          {
            path: paths.agencyPaths.AGENCY_PRACTICE_CENTER,
            element: <AboutGXP />,
          },
        ],
      },
      {
        path: '*',
        element: <>Not found</>,
      },
    ],
  },
  {
    path: paths.AUTH,
    element: <div>Auth</div>,
    children: [],
  },
  {
    path: paths.ADMIN_PANEL,
    element: <Dashbord />,
    children: [
      {
        path: paths.ADMIN_PANEL_GXP,
        element: <GxpPanel />,
        children: [
          {
            path: paths.gxpPaths.ADMIN_PANEL_GXP_HOME,
            element: <GxpHome />,
            index: true,
          },
          {
            path: paths.gxpPaths.ADMIN_PANEL_GXP_COMPANIES,
            element: <GXPCompany />,
          },
          {
            path: paths.gxpPaths.ADMIN_PANEL_GXP_COMPANIES_BRANCHES,
            element: <GXPCompanyBranch />,
          },
          {
            path: paths.gxpPaths.ADMIN_PANEL_GXP_COMPANIES_BRANCH_ADD,
            element: <GXPCompanyAddBranch />,
          },
          {
            path: paths.gxpPaths.ADMIN_PANEL_GXP_PRODUCTS,
            element: <div>Admin panel gxp products</div>,
          },
          {
            path: paths.gxpPaths.ADMIN_PANEL_GXP_REQUESTS,
            element: <GXPApplications />,
          },
          {
            path: paths.gxpPaths.ADMIN_PANEL_GXP_REQUESTS_CREATE,
            element: <CreateApplicationContainer />,
          },
          {
            path: paths.gxpPaths.ADMIN_PANEL_GXP_DOCUMENTS,
            element: <div>Admin panel gxp documents</div>,
          },
          {
            path: paths.gxpPaths.ADMIN_PANEL_GXP_SETTINGS,
            element: <div>Admin panel gxp settings</div>,
          },
        ],
      },
      {
        path: paths.ADMIN_PANEL_REGULATOR,
        element: <RegulatorPanel />,
        children: [
          {
            path: paths.regulatorPaths.ADMIN_PANEL_REGULATOR_HOME,
            element: <RegulatorHome />,
            index: true,
          },
          {
            path: paths.regulatorPaths.ADMIN_PANEL_REGULATOR_STATISTICS,
            element: <RegulatorStatistics />,
          },
          {
            path: paths.regulatorPaths.ADMIN_PANEL_REGULATOR_PROFILE,
            element: <RegulatorProfile />,
          },
          {
            path: paths.regulatorPaths.ADMIN_PANEL_REGULATOR_SETTINGS,
            element: <RegulatorSettings />,
          },
          {
            path: paths.regulatorPaths.ADMIN_PANEL_REGULATOR_HANDBOOK,
            element: <RegulatorHandbook />,
          },
          {
            path: paths.regulatorPaths.ADMIN_PANEL_REGULATOR_HANDBOOK_CREATE,
            element: <RegulatorHandbookCreate />,
          },
        ],
      },
      {
        path: paths.ADMIN_PANEL_PHARM,
        element: <AdminPanel />,
        children: [
          {
            path: '',
            element: <Navigate to={paths.adminPanelPharm.ADMIN_PANEL_PHARM_NEWS} replace />,
          },
          {
            path: paths.adminPanelPharm.ADMIN_PANEL_PHARM_NEWS,
            element: <NewsScreen />,
          },
          {
            path: paths.adminPanelPharm.ADMIN_PANEL_PHARM_ADD_NEWS,
            element: <AddNewsEditor />,
          },
          {
            path: paths.adminPanelPharm.ADMIN_PANEL_PHARM_REFERENCE_PRICES,
            element: <AdminReferencePrices />,
          },
          {
            path: paths.adminPanelPharm.ADMIN_PANEL_PHARM_ADD_REFERENCE_PRICE,
            element: <AddReferencePrice />,
          },
        ],
      },
      {
        path: '*',
        element: <>Not found</>,
      },
    ],
  },
  {
    path: paths.PAGE_NOT_FOUND,
    element: <div>Not found</div>,
  },
])
